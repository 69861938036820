exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-booking-service-js": () => import("./../../../src/pages/booking-service.js" /* webpackChunkName: "component---src-pages-booking-service-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-what-to-do-js": () => import("./../../../src/pages/what-to-do.js" /* webpackChunkName: "component---src-pages-what-to-do-js" */),
  "component---src-templates-hotel-page-js": () => import("./../../../src/templates/hotel-page.js" /* webpackChunkName: "component---src-templates-hotel-page-js" */),
  "component---src-templates-hotel-room-page-js": () => import("./../../../src/templates/hotel-room-page.js" /* webpackChunkName: "component---src-templates-hotel-room-page-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/offer-page.js" /* webpackChunkName: "component---src-templates-offer-page-js" */)
}

